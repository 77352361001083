/* Move down content because we have a fixed navbar that is 50px tall */
body {
    padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Set widths on the form inputs since otherwise they're 100% wide */
input,
select,
textarea {
    max-width: 280px;
}


/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
    .jumbotron {
        margin-top: 20px;
    }

    .body-content {
        padding: 0;
    }
}

.ui-button-icon-only {
    text-indent: -9999px;
}

.ui-dialog .ui-dialog-buttonpane button {
    padding: 4px;
}